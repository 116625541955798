import React from 'react';
import { Link, graphql } from 'gatsby';

import DefaultLayout from '../components/layout';
import SEO from '../components/seo';
import SocialContact from '../components/SocialContact';
import config from '../../data/SiteConfig';

class AboutPage extends React.Component {
  render() {
    const about = this.props.data.site.siteMetadata;
    return (
      <DefaultLayout>
        <SEO title="About" description={config.siteTitle} keywords={[`gobinda`, `about`, `person`, `thakur`]} />
        <div className="pages">
            <h2>About</h2>
            <p>{about.description}</p>
            <div>
                <h3>I am currently exploring.....</h3>
                <ul>
                    <li>JavaScript</li>
                    <li>NodeJS(Particularly the event loop and the thread pool)</li>
                    <li>React</li>
                    <li>Vue</li>
                    <li>Bootstrap</li>
                    <li>Material Design</li>
                    <li>GraphQL</li>
                    <li>Gatsby</li>
                </ul>
            </div>
            <div className="contact">
                <h3 className="contact-title">Contact me</h3>
                <SocialContact social={about.social} />
            </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default AboutPage;

export const pageQuery = graphql`
query SiteAboutQuery {
  site {
    siteMetadata {
      author
      authorRole
      description
      social {
        twitter
        facebook
        linkedin
        github
        email
      }
    }
  }
}
`